const youtubeLinkRegx = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;

export const CONTAINER_CLASS = "yt-vid";
export const ID_PATTERN = "youtube@(\\S+)";

const componentPattern = new RegExp(`^<p class="${CONTAINER_CLASS}">${ID_PATTERN}<\\/p>$`);

export default {
	id: "youtube",
	label: "Youtube",
	fields: [{ name: "id", label: "Youtube Video ID", widget: "string" }],
	pattern: componentPattern,
	fromBlock: function (match) {
		// console.log("!!!!!! fromBlock", match);
		return {
			id: match[1]
		};
	},
	toBlock: function (obj) {
		let id = "";

		if (obj && obj.id) {
			id = ~obj.id.indexOf("http") ?
				youtubeLinkRegx.exec(obj.id) : obj.id;

			if (Array.isArray(id)) {
				id = id[5]; //the 5th part of the match is the video id
			}
		}

		// console.log("!!!!!! toBlock", { obj, id });
		return `<p class="${CONTAINER_CLASS}">youtube@${id}</p>`;
	},
	toPreview: function (obj) {
		// console.log("!!!! to preview = ", obj);
		return `<img src="http://img.youtube.com/vi/${obj.id}/maxresdefault.jpg" alt="Youtube Video"/>`;
	}
};
