import React, { useLayoutEffect, useRef, useContext } from "react";
import YouTubePlayer from "youtube-player";
import { CONTAINER_CLASS, ID_PATTERN } from "../../cms/widgets/YoutubeEditorComponent";
import PreviewContext from "../Layout/PreviewContext";
import { HTMLContent } from "../Content";
import { trackEvent } from "../utils";

const YT_ID_RGX = new RegExp(ID_PATTERN),
	MAX_VIDEO_WIDTH = 640;

const processVideo = (container) => {
	const videoContainers = container.querySelectorAll(`.${CONTAINER_CLASS}`);

	let videoWidth = MAX_VIDEO_WIDTH;

	if (window.innerWidth < MAX_VIDEO_WIDTH) {
		videoWidth = window.innerWidth * 0.9;
	}

	Array.prototype.forEach.call(videoContainers,
		(elm) => {
			const vidIdMatch = YT_ID_RGX.exec(elm.textContent),
				videoId = vidIdMatch && vidIdMatch[1];

			if (videoId) {
				YouTubePlayer(elm, {
					videoId,
					autoplay: 0,
					width: videoWidth,
				});
			}
			else {
				elm.textContent = "";
			}
		});
};

const processLinks = (container) => {

	const links = container.querySelectorAll("a");

	Array.prototype.forEach.call(links,
		(link) => {

			if (!link.getAttribute("target")) {
				link.setAttribute("target", "_blank");
			}

			link.addEventListener("click", (e) => {
				const target = e.target,
					href = target.getAttribute("href");

				trackEvent("content-interaction", "content link click", target.innerText, href);

				if (window.fbq && ~href.indexOf("meshulam")) {
					window.fbq("track", "InitiateCheckout");
				}
			});
		});
};

const CustomHTMLContent = ({ content, className }) => {
	const isPreview = useContext(PreviewContext),
		contentRef = useRef();

	useLayoutEffect(() => {
		if (!isPreview && contentRef.current) {
			processVideo(contentRef.current);
			processLinks(contentRef.current);
		}
	}, [content]);

	return <HTMLContent
		ref={contentRef}
		content={content}
		className={className} />;
};

export default CustomHTMLContent;
