import { graphql } from "gatsby";
import getGenericPage from "./generic/genericPage";

export const errorPageQuery = graphql`
  query ErrorPageQuery($id: String!) {
    markdownRemark(id: {eq: $id }) {
      ...GenericPageFragment
    }
  highlightedEvents: allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "event-page"}, featuredpost: {eq: true}}}, limit: 3) {
    edges {
      ...EventHighlight
    }
  }
}`;

export default getGenericPage();
