import React, { useContext } from "react";
import { graphql } from "gatsby";
import { noop } from "lodash";
import styled from "styled-components";
import SocialShare from "../../components/SocialShare/SocialShare";
import PreviewContext from "../../components/Layout/PreviewContext";
import PageBanner from "../../components/PageBanner/PageBanner";
import PageSection from "../../components/PageSection/PageSection";
import FeaturedEvents from "../../components/FeaturedEvents/FeaturedEvents";
import CustomHTMLContent from "../../components/CustomHTMLContent/CustomHTMLContent";
import AdSenseAd from "../../components/AdNetworks/AdSenseAd";
import withLayoutAndData from "./withLayoutAndData";
import { color } from "../../theme";

// type GenericPageOptions = {
// 	childrenInsideMainSection: boolean,
// 	showSocialShare: Boolean,
// 	showAd?: Boolean,
//   additionalDataTransformer: (Object) => any,
// };

export const Content = styled(CustomHTMLContent)`
	flex-grow: 2;
	margin-bottom: 20px;

	a {
		text-decoration: underline;
		${color("brand")}
	}

	ul, ol {
		li {
			margin-bottom: 10px;
		}
	}
`;

export const GenericPage = ({ page, highlightedEvents, children, options }) => {
	const isPreview = useContext(PreviewContext);
	options = options || {};

	return (<div>
		<PageBanner title={page.title} image={page.image} />

		{isPreview && page.heading && <h2>{page.heading}</h2>}

		{!isPreview && options.showSocialShare &&
			<PageSection>
				<SocialShare {...page} url={page.slug} noMargin />
			</PageSection>}

		<PageSection bottomBorder >
			<Content content={page.content} />
			{options.childrenInsideMainSection ? children : null}
		</PageSection>

		{options.childrenInsideMainSection ? null : children}

		{highlightedEvents &&
			<PageSection bottomBorder>
				<FeaturedEvents
					title={page.eventText}
					items={highlightedEvents}
					page={page.slug} />
			</PageSection>}

		{!isPreview && options.showAd &&
			<AdSenseAd slot="3928202188" format="auto" fullWidthResponsive />}
	</div>);
};

export const GenericPageFragment = graphql`
	fragment GenericPageFragment on MarkdownRemark {
			id
			html
			fields {
				slug
			}
			frontmatter {
				title
				heading
        descriptionRaw
				sections
        eventText
			}
		...RemoteBannerImage
	}`;

const pageNoop = (page) => page;

const getGenericPage = (children, options) => {
	options = options || {};
	const dataTransformer = (options.additionalDataTransformer) || noop;
	const pageTransformer = options.pageTransformer || pageNoop;

	return withLayoutAndData(
		({ data, pageContext }) => ({
			page: pageTransformer({
				...data.markdownRemark.frontmatter,
				...data.markdownRemark.fields,
				content: data.markdownRemark.html,
				...dataTransformer(data, pageContext),
			}, data, pageContext),
			highlightedEvents: data.highlightedEvents,
		}), null, children, options
	)(GenericPage);
};

export default getGenericPage;
